import { allBenefitList } from '../components/tabledata.example';

export const basicBenefits = [
  '2 group cards',
  'Unlimited contributors',
  'Add messages, photos, GIFs & videos',
];

export const standardBenefits = [
  'Unlimited group cards',
  'Bulk creation of cards',
  '10% off illume’s curated gifting marketplace',
  'Split any gift with URL',
];

// remove first one, according to figma
export const benefits = allBenefitList.slice(1);

export const premiumBenefits = [
  'Unlimited contributors',
  'Collect funds for a group gift',
  'Send to multiple recipients',
];

type FilterCallback<T = any> = (element: T, index: number, self: T[]) => boolean;

export const dup: FilterCallback = (elem, index, self) => self.indexOf(elem) === index;
