import * as React from 'react';

import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { theme } from '../../../styles/theme';
import { CheckIcon } from './CheckIcon';
import { WrongIcon } from './WrongIcon';

type Feature<T> = {
  name: string;
  basic: T;
  standard: T;
  premium: T;
};

export const allBenefitList = [
  'Add messages, photos and GIFs',
  'Add videos',
  'Desktop and mobile friendly',
  'Advertisement free',
  'Confetti',
  'Themed backgrounds',
  'Invite contributors via URL',
  'Invite contributors via SMS or email',
  'Schedule delivery',
  'Accessible forever',
  'Privacy between notes',
  'Note suggestion library',
  'Reminders',
  'Collect funds for gift',
  'Download card as PDF',
  'Send to multiple recipients',
  'Priority support',
  'Member-pricing on curated gifts',
  '“Split the gift” of any online gift',
  'Bulk upload cards via .csv',
  'Birthday reminders',
];

const columnHelper = createColumnHelper<Feature<string | boolean>>();

const reminderIndex = allBenefitList.findIndex((e) => e === 'Reminders');

export const defaultData: Feature<string | boolean>[] = [
  {
    name: 'Number of cards',
    basic: '2',
    standard: '1',
    premium: 'Unlimited',
  },
  {
    name: 'Number of contributors',
    basic: 'Unlimited',
    standard: 'Unlimited',
    premium: 'Unlimited',
  },
  ...allBenefitList.map((benefitName, index) => {
    return {
      name: benefitName,
      basic: index > reminderIndex ? false : true,
      standard: true,
      premium: true,
    };
  }),
];

function renderIcon(yes: boolean) {
  return yes ? <CheckIcon size={24} /> : <WrongIcon />;
}

const getCellContent = (info: any) => {
  const value = info.getValue();
  if (typeof value === 'boolean') {
    return renderIcon(value);
  } else {
    return value;
  }
};

export const columns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => 'Feature',
  }),
  columnHelper.accessor('basic', {
    cell: getCellContent,
    header: () => 'Basic',
  }),
  columnHelper.accessor('standard', {
    cell: getCellContent,
    header: () => 'Standard',
  }),
  columnHelper.accessor('premium', {
    cell: getCellContent,
    header: () => 'Premium',
  }),
];

export const baseColumns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => 'Feature',
  }),
  columnHelper.accessor('basic', {
    cell: getCellContent,
    header: () => 'Basic',
  }),
  columnHelper.accessor('standard', {
    cell: getCellContent,
    header: () => 'Standard',
  }),
];

export const slackColumns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => 'Feature',
  }),
  columnHelper.accessor('basic', {
    cell: getCellContent,
    header: () => 'Basic',
  }),
  columnHelper.accessor('premium', {
    cell: getCellContent,
    header: () => 'Standard',
  }),
];
