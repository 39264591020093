import React, { FC, useState } from 'react';

import styled from 'styled-components';

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { defaultData, columns as defaultColumns } from './tabledata.example';
import { theme } from '../../../styles/theme';

interface TableProps<T> {
  data: T;
}

const tableColors = {
  header: theme.colors.lightPeach4,
  headerTextColor: theme.colors.gray100,
  borderColor: theme.colors.lightPeach,
  cellBackground: theme.colors.lightPeach2,
  cellTextColor: theme.colors.gray60,
};

const StyledTable = styled.table`
  width: 100%;
  border-style: hidden;
  border-collapse: collapse;
  text-indent: 0;
  & thead {
    background: ${theme.colors.lightPeach4};
    & th {
      color: ${tableColors.headerTextColor};
      font-weight: 500;
      padding: 15px 0;
    }
  }

  & tbody {
    & td {
      padding: 10px 8px;
      border: 1px solid ${tableColors.borderColor};
      font-size: 10;
      text-align: center;
      color: ${tableColors.cellTextColor};
    }
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${tableColors.borderColor};
`;

export const FeaturesTable: FC<{
  columns?: any;
}> = ({ columns = defaultColumns }) => {
  const [data] = useState(() => defaultData);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Wrapper>
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
};

export default FeaturesTable;
