import DineroFactory from 'dinero.js';

type SupportedCurrencies = 'USD';

export class Money {
  private money;
  static parseDollarToCents(dollar: string): number {
    const parsed = parseFloat(dollar);
    if (isNaN(parsed)) {
      throw new Error('Invalid input value');
    }
    return Math.round(parsed * 100);
  }
  private constructor(cents: number, currency: SupportedCurrencies = 'USD') {
    this.money = DineroFactory({ amount: cents, currency });
  }
  getAmount() {
    return this.money.getAmount();
  }
  equalsTo(money: Money) {
    return this.money.equalsTo(DineroFactory({ amount: money.getAmount() }));
  }
  toFormat() {
    return this.money.toFormat();
  }
  static fromCents(cents: number) {
    return new Money(cents);
  }
  static fromDollarString(dollar: string) {
    return new Money(Money.parseDollarToCents(dollar));
  }
}
