import { Money } from '../../../domain/Money';
import { theme } from '../../../styles/theme';
import { IPricing } from '../PricingSection';
import { basicBenefits, benefits, dup, standardBenefits, premiumBenefits } from './pricing.data';

export const SLACK_PRICING_DATA: IPricing[] = [
  {
    banner: null,
    pricingType: 'SlackBasic',
    label: 'Basic',
    price: Money.fromCents(0),
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: basicBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: undefined,
    note: undefined,
    tag: undefined,
    hiddenBenefits: basicBenefits
      .concat(benefits)
      // remove already included benefits
      .filter(dup)
      // from "Reminders" to the rest, greys it
      .map((text, index, arr) => {
        const reminderIndex = arr.findIndex((e) => e === 'Reminders');
        const moreThanReminderIndex = index > reminderIndex;
        return {
          color: moreThanReminderIndex ? null : theme.colors.orange,
          text,
          textColor: moreThanReminderIndex ? theme.colors.gray40 : null,
        };
      }),
  },

  {
    banner: null,
    pricingType: 'SlackPremium',
    label: 'Standard',
    tag: {
      color: theme.colors.orange,
      title: 'MOST POPULAR',
    },
    benefits: standardBenefits.map((text) => ({ color: theme.colors.orange, text })),
    frequency: 'Per Year',
    note: null,
    price: Money.fromDollarString('199'),
    subtitle:
      'Everything you need for team appreciation—on special occasion and all the moments in-between.',
    hiddenBenefits: standardBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
];

export const B2B_PRICING_DATA: IPricing[] = [
  {
    banner: null,
    pricingType: 'Basic',
    label: 'Basic',
    price: Money.fromCents(0),
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: basicBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: undefined,
    note: undefined,
    tag: undefined,
    hiddenBenefits: basicBenefits
      .concat(benefits)
      // remove already included benefits
      .filter(dup)
      // from "Reminders" to the rest, greys it
      .map((text, index, arr) => {
        const reminderIndex = arr.findIndex((e) => e === 'Reminders');
        const moreThanReminderIndex = index > reminderIndex;
        return {
          color: moreThanReminderIndex ? null : theme.colors.orange,
          text,
          textColor: moreThanReminderIndex ? theme.colors.gray40 : null,
        };
      }),
  },
  {
    banner: { text: 'Save 20%' },
    pricingType: 'Standard',
    label: 'Premium',

    tag: {
      color: theme.colors.orange,
      title: 'MOST POPULAR',
    },
    benefits: standardBenefits.map((text) => ({ color: theme.colors.orange, text })),
    frequency: 'Per Year',
    note: null,
    price: Money.fromDollarString('99'),
    subtitle:
      'Everything you need for unlimited group celebration—on special occasion and all the moments in-between.',
    hiddenBenefits: standardBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
  {
    banner: null,
    pricingType: 'Premium',
    label: 'Standard',
    price: Money.fromDollarString('5.99'),
    frequency: 'Per Card',
    note: null,
    tag: null,
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: premiumBenefits.map((text) => ({ text, color: theme.colors.alertGreen })),
    hiddenBenefits: premiumBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
];
