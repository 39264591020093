import * as React from 'react';

export const WrongIcon = () => {
  return (
    <svg width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.720055 15.0817C0.610084 17.3102 2.24795 19.6835 4.92703 21.485C7.78627 23.4079 10.9567 24.185 14.3729 23.9633C16.343 23.8373 18.1985 23.3006 19.7568 22.0101C20.7699 21.1723 21.5537 20.1432 22.23 19.0231C23.4232 17.0489 24.4223 14.9906 24.9862 12.7388C25.7677 9.6211 25.005 6.86978 23.0114 4.42185C20.922 1.85725 18.1446 0.555108 14.9087 0.125729C12.6461 -0.175305 10.4419 0.0627216 8.30104 0.856142C5.68748 1.82458 3.81095 3.61443 2.62934 6.10202C1.45007 8.58031 0.806628 11.2009 0.720055 15.0817Z'
        fill='#FD6806'
      />
      <g clipPath='url(#clip0_2798_9634)'>
        <path
          d='M17.9504 15.2938C16.5644 13.9079 15.1424 12.5758 13.6484 11.3158C14.6924 9.98382 15.8264 8.74184 17.1764 7.71584C18.0764 7.03181 17.1944 5.48384 16.2764 6.18584C14.8544 7.26584 13.4504 8.61584 12.1904 10.1098C11.1824 9.29984 10.1384 8.48984 9.07641 7.73384C8.14044 7.06787 7.25841 8.59787 8.17641 9.26384C9.16641 9.98384 10.1384 10.7218 11.0924 11.4958C9.81443 13.2238 8.71641 15.0778 7.99641 16.9138C7.58238 17.9759 9.29238 18.4438 9.70641 17.3818C10.3724 15.6898 11.5064 14.1958 12.5684 12.7198C13.9904 13.9439 15.3584 15.2038 16.6724 16.5358C17.5004 17.3638 18.7424 16.1038 17.9504 15.2938L17.9504 15.2938Z'
          fill='#FFFDF3'
        />
      </g>
      <defs>
        <clipPath id='clip0_2798_9634'>
          <rect
            width='14.6667'
            height='14.6667'
            fill='white'
            transform='translate(4.71484 5.33334)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
